import classNames from 'classnames';
import { useTranslations } from 'cupman-utils/lib/useTranslations';
import React, { useState } from 'react';
import { NameClub, Team$shirt as Shirt, Team$awards, useMe, Me$MeTeam, Sport$SportTypes } from '../../api/schema';
import HeartButton from '../ButtonHeart/HeartButton';
import DressBasket from '../Dress/DressBasket';
import DressSoccer from '../Dress/DressSoccer';
import Flag from '../Flag/Flag';
import styles from './TeamHeader.module.scss';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faHeartBroken, faShieldKeyhole } from '@fortawesome/pro-solid-svg-icons';
import useScrolledFromTop from '../../utils/HasScrolledFromTop';
import TeamAwards from './TeamAwards';
import { useMutation } from 'granular';
import { useCurrentTournament } from '../../App';
import CupPassIconBold from '../CupPassIconBold/CupPassIconBold';
import TeamLogoDress from './TeamLogoDress';


type propsTop = {
  sport: Sport$SportTypes,
  dress?: Omit<Shirt,'__typename'>,
  team?: {
    teamName?: string,
    teamId: number,
    teamLogoUrl?: string
  }
  club: NameClub<{
    nation: {}
  }>,
}

const getType = (team: Me$MeTeam) => {

  if (team.hasLeaderAccess) {
    return 'leader'
  }

  if(team.cupPassImageUrl !== undefined) {
    return 'cuppass'
  }

  return 'favorite'
}

export function TeamHeaderTop ({sport, dress, team, club} : propsTop) {

  const T = useTranslations('cmresults');
  const scrolled = useScrolledFromTop(170);

  const t = useCurrentTournament();
  const me = useMe({optionalCupId: t.cup.id},{
    teams: [{
      team: {}
    }]
  });
  const [addFavoriteTeam] = useMutation(me.addFavoriteTeam);
  const [removeFavoriteTeam] = useMutation(me.removeFavoriteTeam);
  const meTeam = me.teams.find(t => t.team?.id === team?.teamId);

  const [likedAlready, setLikedAlready] = useState(!!meTeam);


  const favoriteButton = team && <>
    <button className={classNames(styles.like_button, meTeam && styles.liked)} aria-label={T('Mark X as favorite', team.teamName)} onClick={async () => {
      if (meTeam) {
        removeFavoriteTeam(team.teamId).then(window.trigger_magicbutton_refresh);
        setLikedAlready(false);
        /*toast.dark(T('Du har nu avföljt nu X', team.teamName), {
          icon: <FontAwesomeIcon icon={faHeartBroken} style={{color: 'rgb(var(--clr-favorite))'}} />
        })*/
      } else {
        addFavoriteTeam(team.teamId).then(window.trigger_magicbutton_refresh);
        
        /*toast.dark(T('Du följer nu X', team.teamName), {
          icon: <FontAwesomeIcon icon={faHeart} style={{color: 'rgb(var(--clr-favorite))'}} />
        })*/
      }
    }}>

      
      <HeartButton 
        color="rgb(var(--clr-favorite))" 
        className={styles.like_team_heart} 
        likeStatus={!!meTeam} 
        blowColor="rgb(var(--clr-favorite))" afterColor="rgb(var(--clr-favorite))" activeColor={dress?.color1} 
      />
    </button>
    <span className={styles.favorite_text}>{meTeam ? T('Favorite') : T('Follow')}</span>
  </>

  const leaderInfo = <>
    <FontAwesomeIcon icon={faShieldKeyhole} style={{color: 'rgb(var(--clr-favorite))', fontSize: '1.8rem', filter: 'drop-shadow(0px 0px 3px rgba(var(--clr-favorite), .5))'}} /> <span className={styles.favorite_text}>{T('My team')}</span>
  </>

  const cupPassInfo = <>
    <CupPassIconBold className={styles.cup_pass_icon} /> <span className={styles.favorite_text}>{T('My team')}</span>
  </>







  return <>
    <div className={classNames(styles.team_head_container)}>
      <div className="max-75">
        <div className={styles.club_container}>
          <span>{T.select(club.nation.name)}</span>
          <div className={styles.flag_container}>
            <Flag code={club.nation.code} alt={T.select(club.nation.name)} size="lg" borderColor="rgb(var(--clr-favorite-dark))" />
          </div>
        </div>
        <div className={styles.logo_dress_container}>
          <TeamLogoDress 
            dress={dress} 
            nationCode={club.nation.code} 
            logoImg={team?.teamLogoUrl} 
            sport={sport} 
            scrolled={scrolled}
          />
        </div>

        <div className={styles.favorite_container}>

          {meTeam ? <>
            {/* meTeam exists */}

            {getType(meTeam) === 'favorite' && <>
              {favoriteButton}
            </>}

            {getType(meTeam) === 'leader' && <>
              {leaderInfo}
            </>}

            {getType(meTeam) === 'cuppass' && <>
              {cupPassInfo}
            </>}
          </>
          : <>
            {favoriteButton}
          </>}
        </div>
      </div>
    </div>
    
   
  </>
}




type propsBottom = {
  formGuide?: string[][],
  teamShortStats?: {
    wins?: number,
    losses?: number,
    ties?: number,
    goals?: number
  },
  awards?: Team$awards
}

export function TeamHeaderBottom ({awards, formGuide, teamShortStats} : propsBottom) {

  const T = useTranslations("cmresults");
  
  
  const formHtml = (form:string) => {
    if (form === 'win') {
      return <span className={classNames(styles.form_win,styles.form)}>{T('V')}</span>
    }
    if (form === 'loss') {
      return <span className={classNames(styles.form_loss,styles.form)}>{T('F')}</span>
    }
    if(form === '-') {
      return <span className={classNames(styles.form_tie,styles.form)}>-</span>
    }
    return <span className={classNames(styles.form_tie,styles.form)}>{T('O')}</span> // Tie
  }

return <>
 {teamShortStats && formGuide && <div className={styles.teamstats_container}>
      <div className='max-75'>

        {teamShortStats && <div className={classNames(styles.form_guide, styles.stats)}>
          <div className={styles.inner_stats}>
            <div>
              <h3 className={classNames(styles.club_team_h2, 'custom-font')}>{T('Wins')}</h3>
              <span className={classNames(teamShortStats.wins === 0 ? styles.form_tie : styles.form_win)}>{teamShortStats.wins}</span>
            </div>

            <div>
              <h3 className={classNames(styles.club_team_h2, 'custom-font')}>{T('Ties')}</h3>
              <span className={styles.form_tie}>{teamShortStats.ties}</span>
            </div>

            <div>
              <h3 className={classNames(styles.club_team_h2, 'custom-font')}>{T('Losses')}</h3>
              <span className={classNames(teamShortStats.losses === 0 ? styles.form_tie : styles.form_loss)}>{teamShortStats.losses}</span>
            </div>

            {/*<div>
              <h3 className={styles.club_team_h2}>{T('Gjorda mål')}</h3>
              <span className={styles.form_tie}>{teamShortStats.goals}</span>
            </div>*/}
          </div>
        </div>}

        {formGuide && <div className={styles.form_guide}>
          <div className={classNames(styles.form_guide_inner, styles.form_guide_inner_games)}>
            <h2 className={classNames(styles.club_team_h2, 'custom-font')}>
              {T('Form guide')}
            </h2>
            <div className={styles.form_games}>
              {formGuide.map((form, outerI, {length}) => {

                return <React.Fragment key={outerI}>
                  <div>

                    {form.map((innerForm, i) => <React.Fragment key={i}>
                      {formHtml(innerForm)}
                    </React.Fragment>)}
                  </div>
                  {length -1 !== outerI && <span className={styles.separator} role="presentation"></span>}
                </React.Fragment>
              }
              )}
            </div>
          </div>
        </div>}

        
      </div>
    </div>}
    {awards && awards.length > 0 && <TeamAwards awards={awards}/>}
  </>
}